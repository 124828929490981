/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { usePagination, useSortBy, useTable, useFilters, useGlobalFilter } from 'react-table';
import {
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  fuzzyTextFilterFn,
  getHiddenColumns, IndeterminateCheckbox, returnCurrentSeason
} from "../TableFilters/TableFilters";
import Modal from 'react-modal';
import RefundClinicModal from './RefundClinicModal';
import '../../style/modal.css';
import payBalance from '../../utils/payBalance'
import { Box, Flex } from '../../style/basicStyle';
import toast from '../../utils/toast';
import { withFirebase } from '../Firebase';
import { TableStyles, IconInline } from '../../style/tableStyling.js';
import feather from "../../utils/feather";
import { disableBrowserStyling, displayInline } from "../../style/genericStyling";


Modal.setAppElement('#___gatsby');


// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table({ columns, data, initialState }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getToggleHideAllColumnsProps,
    allColumns
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: 'teamClinicName',
            desc: false
          }
        ],
        hiddenColumns: getHiddenColumns(columns),
        filters: [
          {
            id: 'season',
            value: returnCurrentSeason(data),
          },
        ],
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
  );


  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
        </div>
        {/* Loop through columns data to create checkbox */}
        {allColumns.map((column) => (
          <div className="cb action" key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              <span>{column.Header}</span>
            </label>
          </div>
        ))}
        <br />
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps([
                { className: column.className },
                ])}>
                  <span  {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>{column.disableSortBy ? "" : column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </span>
                <div {...column.getHeaderProps([
                  { className: column.className },
                ])}>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
        <tr>
          <th
            colSpan={visibleColumns.length}
            style={{
              textAlign: 'left',
            }}
          >
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </th>
        </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const teamClinicRecord = (teamClinic, idToken, authUser, toggleModal) => {
  const { teamClinicOption } = teamClinic;
  const teamClinicCost = (teamClinicOption && teamClinicOption.raw) || teamClinic.teamClinicCost;
  return {
    idToken,
    toggleModal,
    authUser,
    teamClinic,
    docId: teamClinic.docId,
    teamClinicId: teamClinic.teamClinicId,
    teamClinicDates: teamClinic.teamClinicDates,
    teamClinicDescription: teamClinic.teamClinicDescription,
    teamClinicName: teamClinic.teamClinicName,
    teamClinicYear: teamClinic.teamClinicYear,
    name: teamClinic.name,
    email: teamClinic.email,
    phone: teamClinic.phone,
    playerName: teamClinic.playerName,
    playerBirthday: teamClinic.playerBirthday,
    playerPosition: teamClinic.playerPosition || '',
    division: teamClinic.division || '',
    season: teamClinic.seasonYear,
    balance: `$${teamClinic.balance}`,
    clinicSelection: (teamClinicOption && teamClinicOption.description) || '',
    teamClinicCost,
    paid: payBalance(teamClinic),
    paidDateSort: teamClinic.paidDateSort,
    updatedAtSort: teamClinic.updatedAtSort,
    paidDate: teamClinic.paidDate,
    updatedAt: teamClinic.updatedAt,
  };
};

const columns = [
  {
    Header: 'Team Clinics',
    columns: [
      {
        Header: 'Clinic',
        accessor: 'teamClinicId',
        Filter: SelectColumnFilter,
        filter: 'includes',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Name',
        accessor: 'name',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Email',
        accessor: 'email',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Phone',
        accessor: 'phone',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Player 1',
        accessor: 'playerName',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Division 1',
        accessor: 'division',
        Filter: SelectColumnFilter,
        filter: 'includes',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Player Birthday',
        accessor: 'playerBirthday',
        disableFilters: true,
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Player Position',
        accessor: 'playerPosition',
        Filter: SelectColumnFilter,
        filter: 'includes',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Season',
        accessor: 'season',
        Filter: SelectColumnFilter,
        filter: 'includes',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Session',
        accessor: 'clinicSelection',
        Filter: SelectColumnFilter,
        filter: 'includes',
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Balance',
        accessor: 'balance',
        disableFilters: true,
        className: 'limitWidth',
        headerClassName: 'limitWidth',
        hideDefault: true
      },
      {
        Header: 'Payment Status',
        accessor: 'paid',
        disableFilters: true,
        className: 'limitWidth',
        headerClassName: 'limitWidth'
      },
      {
        Header: 'Paid Date',
        accessor: 'paidDateSort',
        disableFilters: true,
        className: 'limitWidth',
        headerClassName: 'limitWidth',
        Cell: ({ row: { original } }) => {
          let { paidDate } = original;
          let { paidDateSort } = original;
          let sortResults;
          try {
            sortResults = paidDateSort && (
              <span>{paidDate}</span>
            );
          } catch (e) {
            console.log('paidDateSort CELL error: ', e);
          }
          return sortResults || null;
        },
        hideDefault: true
      },
      {
        Header: 'Updated Date',
        accessor: 'updatedAtSort',
        disableFilters: true,
        className: 'limitWidth',
        headerClassName: 'limitWidth',
        Cell: ({ row: { original } }) => {
          let { updatedAt } = original;
          let { updatedAtSort } = original;
          let sortResults;
          try {
            sortResults = updatedAtSort && (
              <span>{updatedAt}</span>
            );
          } catch (e) {
            console.log('updatedAt CELL error: ', e);
          }
          return sortResults || null;
        },
        hideDefault: true
      },
      {
        Header: 'Action',
        accessor: 'docId',
        disableSortBy: true,
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { docId } = original;
          const { authUser } = original;
          const { toggleModal } = original;
          const action = docId && (
            <IconInline>
              {authUser && authUser.roles && (authUser.roles.CHANGEPAYMENT || authUser.roles.ADMIN) && (
                <span css={[disableBrowserStyling, displayInline]} tabIndex="0" role="button" onKeyPress={toggleModal('refundClinicModal', original)} onClick={toggleModal('refundClinicModal', original)}>
                  {feather('edit', ['25', '25'])}
                </span>
              )}
            </IconInline>
          );
          return action || null;
        }
      }
    ]
  }
];

class TeamClinicsAdmin extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      modalData: {},
      teamClinicList: [],
      currentModal: null
    };
  }

  componentDidMount() {
    this.getTeamClinicsList();
  }

  componentDidUpdate() {
    this.getTeamClinicsList();
  }
  toggleModal = (key, modalData) => (event) => {
    event.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      this.setState({
        modalData: {}
      });
      return;
    }

    this.setState({
      currentModal: key,
      modalData
    });
  };

  handleModalCloseRequest = (saveData) => {
    // if we want to run any additional validation here, we can.
    // right now im handling my validation within the modal form submission.
    if (saveData) {
      // console.log('handleModalCloseRequest - saveData request: ', saveData);
      const valid = true;
      if (valid) {
        // console.log('handleModalCloseRequest! valid request');
        // opportunity to validate something and keep the modal open even if it
        // requested to be closed
        this.setState({
          currentModal: null
        });
      } else {
        // console.log('handleModalCloseRequest! NOT VALID DATA');
        // opportunity to validate something and keep the modal open even if it
        // requested to be closed
      }
    } else {
      this.setState({
        currentModal: null
      });
    }
  };

  handleOnAfterOpenModal = () => {
    // when ready, we can access the available refs.
    this.heading && (this.heading.style.color = '#F00');
  };

  getTeamClinicsList = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.firebase.auth.currentUser
            // eslint-disable-next-line react/prop-types
            .getIdToken(/* forceRefresh */ true)
            .then(function getIdToken(idToken) {
              const adminRequest = true;
              const values = { idToken, adminRequest };
              fetch('/.netlify/functions/getTeamClinics', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              })
                .then((result) => {
                  try {
                    const resultJSON = result.json();
                    resultJSON
                      .then(function processResult(r) {
                        if (result.status === 200) {
                          self.setState({
                            // eslint-disable-next-line react/no-unused-state
                            teamClinicList: r.message,
                            loading: false,
                            idToken
                          });
                        } else {
                          console.log('teamClinic request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                          toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                        }
                      })
                      .catch((error) => {
                        console.log('teamClinic request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                        toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                      });
                  } catch (e) {
                    console.log('teamClinic request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                    toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                })
                .catch((error) => {
                  console.log('teamClinic request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                  toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                });
            })
            .catch(function getIdTokenError(error) {
              console.log('teamClinic - getIdTokenError - error is: ', error);
            });
        }
      });
    }
  };

  formatTable() {
    const { state } = this;
    const { props } = this;
    const { toggleModal } = this;
    const { teamClinicList } = state;
    const { idToken } = state;
    const length = teamClinicList && teamClinicList.length;
    const authUser = props && props.firebase && props.firebase.authUser;
    const formatTableLevel = (depth = 0) => {
      return teamClinicList.map((teamClinic) => {
        return {
          ...teamClinicRecord(teamClinic, idToken, authUser, toggleModal),
          subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
        };
      });
    };
    return formatTableLevel();
  }

  render() {
    const { loading } = this.state;

    const { currentModal } = this.state;


    return (
      <>
        {loading && <div>Loading ...</div>}

        <RefundClinicModal
          modalData={this.state.modalData}
          isOpen={currentModal === 'refundClinicModal'}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          askToClose={this.toggleModal('refundClinicModal')}
          idToken={this.state.idToken}
        />


        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(TeamClinicsAdmin);
