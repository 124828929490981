/* eslint-disable react/button-has-type, react/prop-types */

import React from 'react';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { css } from '@emotion/react';
import moment from 'moment';
import { useSortBy, useTable } from 'react-table';
import { Box, BoxInline, BoxVisible, Container, Flex } from '../../style/basicStyle';
import payBalance from '../../utils/payBalance';
import toast from '../../utils/toast';
import { disableBrowserStyling, visuallyHidden } from '../../style/genericStyling';
import { contactInput, formSubmitButton } from '../../style/formStyling';
import { ButtonSecondary } from '../Buttons';
import { getHiddenColumns } from "../TableFilters/TableFilters";

const checkboxLarger = css`
  margin-left: 15px;
  transform: scale(2);
`;

const manualPaymentStyle = css`
  margin-right: 15px;
`;

const TableStyles = styled.div`
  padding: 0rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`;
function Table({ columns, data, initialState }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [
          {
            id: 'manualPaymentDate',
            desc: true
          }
        ],
        hiddenColumns: getHiddenColumns(columns),
      }
    },
    useSortBy
  );

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

const columns = [
  {
    Header: 'Update History',
    columns: [
      {
        Header: 'Manual Payment',
        accessor: 'manualPayment'
      },
      {
        Header: 'Manually Paid',
        accessor: 'manuallyPaid'
      },
      {
        Header: 'Refund Status',
        accessor: 'refundRequested'
      },
      {
        Header: 'Refund Requested',
        accessor: 'refundRequestDate'
      },
      {
        Header: 'Note',
        accessor: 'message'
      },
      {
        Header: 'User',
        accessor: 'username'
      },
      {
        Header: 'Email',
        accessor: 'email'
      },
      {
        Header: 'Date',
        accessor: 'manualPaymentDate'
      }
    ]
  }
];

const historyRecord = (history) => ({
  refundRequested: (history.refundRequested && !history.refundProcessedDate && 'Processing') || 'false',
  manualPayment: (history.manualPayment && 'true') || 'false',
  manuallyPaid: (history.manuallyPaid && 'true') || 'false',
  manualPaymentDate: moment(history.manualPaymentDate).format('MMMM DD, YYYY'),
  refundProcessedDate: moment(history.refundProcessedDate).format('MMMM DD, YYYY'),
  refundRequestDate: moment(history.refundRequestDate).format('MMMM DD, YYYY'),
  message: history.message,
  username: history.username,
  email: history.email
});

export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { modalData, isOpen, askToClose, onAfterOpen, onRequestClose, idToken } = props;

  const { manuallyPaid } = modalData && modalData.teamClinic || false;
  const { manualPayment } = modalData && modalData.teamClinic || false;
  const { refundRequested } = modalData && modalData.teamClinic || false;

  const { refundRequestFailed } = modalData && modalData.teamTryout || false;

  const { manualPaymentHistory } = modalData && modalData.teamClinic || [];

  let stateChanged;
  const handleOnChange = (event) => {
    // stateChanged = event.target && event.target.checked;
    stateChanged = true; // ANY change here requires a note.
  };

  const schemaValidate = (myProps) => {
    if (stateChanged) {
      // console.log('state of check box changed - we now require a note to save');
      return Yup.object().shape({
        message: Yup.string().min(10, 'Note too short!').max(250, 'Note too long!').required('Changing payment state requires a note.'),
        manualPayment: Yup.boolean().oneOf([true, false], 'Alter Payment Method').optional('Set Payment Method to Manual'),
        manuallyPaid: Yup.boolean().oneOf([true, false], 'Parent has completed payment').optional('Set Payment to Paid'),
        refundRequested: Yup.boolean().oneOf([true, false], 'Refund Requested').optional('Refund Payment')
      });
    }
    // console.log('state of check box unchanged - we do not require a note');
    return Yup.object().shape({
      message: Yup.string().min(10, 'Note too short!').max(250, 'Note too long!').optional('Changing payment state requires a note.'),
      manualPayment: Yup.boolean().oneOf([true, false], 'Alter Payment Method').optional('Set Payment Method to Manual'),
      manuallyPaid: Yup.boolean().oneOf([true, false], 'Parent has completed payment').optional('Set Payment to Paid'),
      refundRequested: Yup.boolean().oneOf([true, false], 'Refund has been requested').optional('Refund Payment')
    });
  };

  const formatTable = () => {
    const length = manualPaymentHistory && manualPaymentHistory.length;
    if (length) {
      const formatTableLevel = (depth = 0) =>
        manualPaymentHistory.map((order) => ({
          ...historyRecord(order, idToken),
          subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
        }));
      return formatTableLevel();
    }
    return [];
  };

  const paymentUrl = '/.netlify/functions/changePaymentMethodTeamClinics';
  return (
    <Modal id="refundClinicModal" contentLabel="refundClinicModal" closeTimeoutMS={150} isOpen={isOpen} onAfterOpen={onAfterOpen} onRequestClose={onRequestClose}>
      <h1>Edit Payment - {modalData.name}</h1>
      <Box>
        <h3>Parent Name: {modalData.name}</h3>
        <h3>Player Name: {modalData.playerName}</h3>
        <h3>Payment Status: {payBalance(modalData.teamClinic)}</h3>
        <h3>Payment Date: {modalData.paidDate}</h3>
      </Box>
      <Box>
        <TableStyles>
          <Table
            columns={columns}
            data={formatTable()}
          />
        </TableStyles>
      </Box>
      {!refundRequested || refundRequestFailed ? (<Box p={[1, 2, 3]} fontSize={[2, 3]} width={[1]}>
        <Box textAlign="center" p={[1, 2, 3]}>
          <Formik
            initialValues={{
              'bot-field': '',
              message: ''
            }}
            validationSchema={schemaValidate}
            onSubmit={(values, { setSubmitting }) => {
              const paymentValues = values;
              if (paymentValues.manuallyPaid) {
                paymentValues.manualPayment = true;
              }
              paymentValues.idToken = idToken;
              paymentValues.docId = modalData.docId;
              let proceed;
              if (paymentValues.message && paymentValues.message.length > 1) {
                proceed = true;
              }
              if (manualPayment !== paymentValues.manualPayment) {
                proceed = true;
              }
              if (manuallyPaid !== paymentValues.manuallyPaid) {
                proceed = true;
              }
              if (refundRequested !== paymentValues.refundRequested) {
                proceed = true;
              }
              // console.log('refundClinicModal paymentValues: ', paymentValues);
              if (proceed) {
                toast('Processing payment change request...');
                fetch(paymentUrl, {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(paymentValues)
                })
                  .then((result) => {
                    try {
                      const resultJSON = result.json();
                      resultJSON
                        .then((r) => {
                          if (result.status === 200) {
                            const returnMessage = r.message;
                            console.log('refundClinicModal form success - submitted values: ', paymentValues, ' | returnMessage: ', returnMessage);
                            toast(`Successfully Updated: ${returnMessage}`);
                            onRequestClose();
                            window.location.reload();
                          } else {
                            const errorMessage = r.message;
                            console.log('refundClinicModal form ERROR 1 - submitted values: ', paymentValues, ' | paymentUrl: ', paymentUrl, ' error is: ', errorMessage);
                            toast(`Error processing: ${errorMessage}`);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                          console.log('refundClinicModal form ERROR 2 - submitted values: ', paymentValues, ' | paymentUrl: ', paymentUrl, ' error is: ', error);
                          toast(`Error processing: ${JSON.stringify(error)}`);
                        });
                    } catch (e) {
                      console.log('refundClinicModal form ERROR 22 - submitted values: ', paymentValues, ' | res is: ', result, ' | paymentUrl: ', paymentUrl, ' error is: ', e);
                      toast(`Error processing: ${e.message}`);
                    }
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    setSubmitting(false);
                    console.log('refundClinicModal form ERROR 3 - submitted values: ', paymentValues, ' | paymentUrl: ', paymentUrl, ' error is: ', error);
                    toast(`Error processing: ${error.message}`);
                  });
              } else {
                setSubmitting(false);
                onRequestClose();
              }
            }}
          >
            {({ isSubmitting, isValid, submitForm, values, errors }) => {
              const errCheck = [];
              Object.keys(errors).map((key) => errCheck.push(errors[key]));
              let errResponse;
              if (isSubmitting && errCheck.length === 1) {
                [errResponse] = errCheck;
              } else if (isSubmitting && errCheck.length) {
                errResponse = `${errCheck[0]} and ${errCheck.length - 1} other fields missing.`;
              }
              if (errResponse) {
                // need to give toastId to prevent duplicates. For some reason the form is getting received twice, and I cannot figure it out.
                toast(errResponse);
              }
              return (
                <Form onChange={handleOnChange} name="changepayment" netlify="true" data-netlify="true" data-netlify-honeypot="bot-field">
                  <Field css={visuallyHidden} type="hidden" name="form-name" value="changepayment" />
                  <Field css={visuallyHidden} type="text" name="bot-field" />
                  <BoxVisible textAlign="left" fontSize={[3]} p={[1, 2, 2, 2]} m={[1]} width={[1]}>
                    <Box my={[2]}>Change Payment Method</Box>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="manualPayment">
                      <Field css={[checkboxLarger, manualPaymentStyle]} id="manualPayment" type="checkbox" name="manualPayment" />
                    </label>
                    <BoxInline>Parent is paying directly</BoxInline>
                    <Box fontSize={[2]} p={[1]}>
                      <ErrorMessage name="manualPayment" component="div" />
                    </Box>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor="manuallyPaid">
                      <Field css={[checkboxLarger, manualPaymentStyle]} id="manuallyPaid" type="checkbox" name="manuallyPaid" />
                    </label>
                    <BoxInline>Parent has completed payment.</BoxInline>
                    <Box fontSize={[2]} p={[1]}>
                      <ErrorMessage name="manuallyPaid" component="div" />
                    </Box>
                    <label htmlFor="refundRequested">
                      <Field css={[checkboxLarger, manualPaymentStyle]} id="refundRequested" type="checkbox" name="refundRequested" />
                    </label>
                    <BoxInline>Refund Payment.</BoxInline>
                    <Box fontSize={[2]} p={[1]}>
                      <ErrorMessage name="refundRequested" component="div" />
                    </Box>
                  </BoxVisible>
                  <Box width={[1]} p={[2, 3]}>
                    <Field css={[disableBrowserStyling, contactInput]} rows="5" component="textarea" type="text" name="message" placeholder="Add Note" />
                    <Box fontSize={[2]} p={[1]}>
                      <ErrorMessage name="message" component="div" />
                    </Box>
                  </Box>
                  <Box width={[1]} p={[2, 3]}>
                    <Container>
                      <Box width={[1, 1 / 3]} textAlign="left">
                        <ButtonSecondary type="button" css={formSubmitButton} disabled={isSubmitting} onClick={submitForm}>
                          Save
                        </ButtonSecondary>
                        <ButtonSecondary type="button" css={formSubmitButton} disabled={isSubmitting} onClick={askToClose}>
                          Cancel
                        </ButtonSecondary>
                      </Box>
                    </Container>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Box>) : (
        <Box width={[1]} p={[2, 3]}>
          <Container>
            <Box width={[1, 1 / 3]} textAlign="left">
              <ButtonSecondary type="button" css={formSubmitButton} onClick={askToClose}>Close</ButtonSecondary>
            </Box>
          </Container>
        </Box>
      )}
    </Modal>
  );
};
